import React from 'react';
import { LCLandingContainer } from '@cms-components/learning-center/lc-landing/lc-landing.styles';
import { LCMenu } from '@shared/components/learning-center/lc-menu/lc-menu';
import { TopicsFilterProvider } from '@shared/components/learning-center/context/topics-filter.context';
import { LCArticleDetail } from '@shared/components';
import { LCArticleProps, LCArticleData } from './lc-article.interface';
import { lcArticleMapper } from './mappers/lc-article.mapper';
import { LCMainArticleList } from '@shared/components';

export const LCArticle = (props: LCArticleProps) => {
  const { articleDetail, relatedContent, menu } = props || {};

  return (
    <LCLandingContainer>
      <LCMenu
        filters={[]}
        menu={menu}
        logoText={'Learning Center'}
        backgroundColorMenu={'dark'}
        backgroundColorFilter={'light'}
      />

      <LCArticleDetail {...articleDetail} />

      <LCMainArticleList {...relatedContent} />
    </LCLandingContainer>
  );
};

export const MappedLCArticle = ({ pageContext }: LCArticleData) => {

  const data = lcArticleMapper(pageContext);
  return (
    <TopicsFilterProvider topics={[]}>
      <LCArticle {...data} />
    </TopicsFilterProvider>
  );
};
