import { LCArticleProps, LCArticle } from './../lc-article.interface';
import { CSLCArticle, CSLCContentType, CSLCTopic } from '@shared/contentstack';
import { menuNavMapper } from '@src/shared/mappers/menuNav.mapper';

const maxRelatedArticles = 3;

const filterRelatedArticles = (
  categorySelected: CSLCContentType,
  topicsSelected: CSLCTopic[],
  allArticles: CSLCArticle[]
): CSLCArticle[] => {
  const matchTopics = (topics: CSLCTopic[]): boolean => {
    const idTopics = topics?.map(({ id }) => id);
    return topicsSelected?.every(({ id }) => idTopics?.includes(id));
  };

  const matchCategory = (category: CSLCContentType): boolean =>
    categorySelected?.id === category?.id;

  // Match Topics And Category
  const articlesMatchTopicsAndCategory: CSLCArticle[] = allArticles.filter(
    ({ topics, lc_content_type }) =>
      matchTopics(topics) && matchCategory(lc_content_type[0])
  );

  if (articlesMatchTopicsAndCategory.length >= maxRelatedArticles)
    return articlesMatchTopicsAndCategory;

  // Match Only Topics
  const articlesMatchTopics: CSLCArticle[] = allArticles.filter(({ topics }) =>
    matchTopics(topics)
  );

  if (articlesMatchTopics.length >= maxRelatedArticles) {
    return articlesMatchTopics;
  }

  // Match Only Category
  const articlesMatchCategory: CSLCArticle[] = allArticles.filter(
    ({ lc_content_type }) => matchCategory(lc_content_type[0])
  );

  if (articlesMatchCategory.length >= maxRelatedArticles) {
    return articlesMatchCategory;
  }

  return allArticles;
};

export function lcArticleMapper(data: LCArticle): LCArticleProps {
  const {
    topics,
    headline,
    featured_image,
    publication_date,
    content,
    boxed_promo,
    learningCenterLanding,
    allArticles = [],
    lc_content_type,
    rootPage,
    sections,
    site_configuration,
  } = data || {};

  const image = featured_image?.image;
  const category = lc_content_type[0];
  const { menu } = menuNavMapper([], sections, rootPage);

  return {
    menu,
    articleDetail: {
      topics: topics?.map(({ display_name }) => display_name),
      headline,
      image,
      imageCaption: featured_image.caption,
      publicationDate: publication_date,
      content,
      boxedPromoData: boxed_promo,
    },
    relatedContent: {
      title:
        learningCenterLanding.detail_page_configuration
          .related_content_component_title,
      articleList: filterRelatedArticles(category, topics, allArticles)
        .slice(0, maxRelatedArticles)
        .map(
          ({
            featured_image,
            topics,
            headline,
            path,
            teaser_text,
            lc_content_type,
            logo_card,
            path_category,
            uid
          }) => {
            const category = lc_content_type[0];
            return {
              image: featured_image?.image,
              logoCard: {
                image: logo_card?.logo,
                background: logo_card?.card_color?.colors,
              },
              fallbackImage: site_configuration?.featured_article_fallback_image,
              topics: topics?.map(({ display_name }) => display_name),
              headline,
              headlineLink: path,
              teaser: teaser_text,
              category: category?.landing_page_name,
              categoryLink: path_category || '',
              uid,
              topicColor: '',
              teaserColor: '',
              categoryLinkColor: '',
            };
          }
        ),
      variant: 'mid',
    },
  };
}
