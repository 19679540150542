import React from 'react';
import { MappedLCArticle } from '@cms-components/learning-center/lc-article/lc-article';
import { Helmet } from 'react-helmet';
import {
  filterDefinedComponents,
  mappedComponent,
} from '@shared/utilities/page.util';

function PageLearningCenterCategory({ pageContext }: any) {
  const { learningCenterLanding } = pageContext || {};

  return (
    <>
      <Helmet>
        <meta property="og:type" content="article"></meta>
      </Helmet>
      <MappedLCArticle pageContext={pageContext} />

      {filterDefinedComponents(
        learningCenterLanding.detail_page_components
      ).map((comp: any, index: number) =>
        mappedComponent(comp.type, comp, index)
      )}
    </>
  );
}
export default PageLearningCenterCategory;
